import React, { useEffect, useState } from "react";
import "../styles/navbar.css";
import { IoMenu } from "react-icons/io5"; // Import the menu icon
import { Link } from "react-router-dom";

const logo = require("../images/oengoo_logo_color.png");

const navItems = [
  { text: "Team", link: "/team" },
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setIsNavbarFixed(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar ${isNavbarFixed && "fixed"} ${
        isMenuOpen && "open"
      } md:p-4 p-3 w-[100%] z-50`}
    >
      <div  className="md:w-[80%] w-[100%] mx-auto flex flex-wrap items-center justify-between">
        {/* Logo */}
        <Link to="/">
          <div className="flex items-center flex-shrink-0 text-white ">
            <img
              src={logo}
              alt="Main Logo"
              className="lg:w-[80%] md:w-48 w-32"
            />
          </div>
        </Link>

        {/* Menu Button for medium and small screens */}
        <div className="block lg:hidden">
          <div className="flex gap-3">
            <div className="flex items-center">
              <a href="/#download">
                <button className="px-3 py-2 text-sm font-semibold text-black bg-white rounded-full shadow-md hover:text-white gradient-bg">
                  Download
                </button>
              </a>
            </div>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="flex items-center px-2 py-1 text-black border border-black rounded"
            >
              {isMenuOpen ? <span>&times;</span> : <IoMenu />}
            </button>
          </div>
        </div>

        {/* Navbar Items for large screens */}
        <div
          className={`lg:flex lg:items-center lg:w-auto w-full ${
            isMenuOpen ? "block " : "hidden"
          }`}
          id="menu"
        >
          <ul className="flex flex-col justify-center list-none lg:flex-grow lg:justify-normal lg:flex-row lg:ml-auto">
            {navItems.map((item, index) => (
              <li className="relative nav-item" key={index}>
                <a
                  href={item.link}
                  className={`px-4 py-2 flex items-center justify-center ${
                    isNavbarFixed
                      ? "text-black "
                      : isMenuOpen
                      ? " text-black"
                      : "text-white"
                  } font-semibold`}
                >
                  {item.text}
                </a>
              </li>
            ))}
          </ul>

          {/* Download Button */}
          <div className="items-center hidden lg:flex ms-4">
            <a href="/#download">
              <button className="px-4 py-2 font-semibold text-black bg-white rounded-full shadow-md hover:text-white gradient-bg">
                Download
              </button>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
