import React from "react";
import bannerbg from "../images/banner_bg.jpg"
import logo from "../images/LogoOengoo.png"

const Banner = ({title}) => {
  return (
    <div
      className=""
      
    >
      <div className="w-[100%] relative h-[33rem]  bg-teal-500 flex justify-center items-center">
        <div className="  inset-0 flex flex-col justify-center items-center">
          <h1 className="text-8xl font-normal text-center mt-5">HMIS</h1>
          {/* <h1 className="text-white text-3xl font-normal text-center mt-2">Your fitness partner</h1> */}
          <h1 className="text-4xl font-bold text-center mt-4">{title}</h1>
        </div>
        {/* <img src={logo} alt="Logo" className="absolute top-0 left-0 w-1/3" /> */}
      </div>
    </div>
  );
};

export default Banner;
