import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Banner from "../../components/banner";

const PrivacyPolicy = () => {
  const scrollToSection = (event, sectionId) => {
    event.preventDefault();

    const section = document.getElementById(sectionId);

    if (section) {
      const offset = section.offsetTop;
      const windowHeight = window.innerHeight;
      const scrollOffset = offset - windowHeight / 2;
      window.scrollTo({ top: scrollOffset, behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);
  return (
    <>
      <Navbar />
      <Banner title={"Privacy Policy"} />
      <div className="my-4" id="top">
        <div className="md:w-[80%] w-full mx-auto md:p-8 px-4">
          <h1 className="text-3xl font-bold my-10 text-center">
            Privacy Policy
          </h1>
          <p className="text-gray-700 mb-4 text-left">
            Last updated October 03, 2023
          </p>
          {/* About */}
          <div className="mb-10">
            <p className="text-gray-700 mb-4">
              This privacy notice for Oengoo Private Limited (doing business as
              Oengoo) ("<span className="font-bold">we</span>," "
              <span className="font-bold">us</span>," or "
              <span className="font-bold">our</span>"), describes how and why we
              might collect, store, use, and/or share ("
              <span className="font-bold">process</span>") your information when
              you use our services ("<span className="font-bold">Services</span>
              "), such as when you:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4 ps-3">
              <li>
                Download and use our mobile application (Oengoo - Your Fitness
                Partner), or any other application of ours that links to this
                privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">Questions or concerns?</span> Reading
              this privacy notice will help you understand your privacy rights
              and choices. If you do not agree with our policies and practices,
              please do not use our Services. If you still have any questions or
              concerns, please contact us at publishing@oengoo.com.
            </p>
          </div>
          {/* Summary of key points */}
          <div className="mb-10">
            <h2 className="text-2xl font-semibold mb-2">
              SUMMARY OF KEY POINTS
            </h2>
            <p className="text-gray-700 mb-4 font-bold">
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                What personal information do we process?
              </span>{" "}
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use. Learn more about personal information you disclose to us.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                Do we process any sensitive personal information?
              </span>{" "}
              We may process sensitive personal information when necessary with
              your consent or as otherwise permitted by applicable law. Learn
              more about sensitive information we process.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                Do we receive any information from third parties?
              </span>{" "}
              We do not receive any information from third parties.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                How do we process your information?
              </span>{" "}
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so. Learn
              more about how we process your information.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                In what situations and with which parties do we share personal
                information?
              </span>{" "}
              We may share information in specific situations and with specific
              third parties. Learn more about when and with whom we share your
              personal information.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                How do we keep your information safe?
              </span>{" "}
              We have organizational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Learn more about how we keep your information safe.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold"> What are your rights? </span>
              Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your
              personal information. Learn more about your privacy rights.
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                How do you exercise your rights?
              </span>{" "}
              How do you exercise your rights? The easiest way to exercise your
              rights is by submitting a data subject access request, or by
              contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </p>
            <p className="text-gray-700 mb-4">
              Want to learn more about what we do with any information we
              collect? Review the privacy notice in full.
            </p>
          </div>
          {/* Table of content */}
          <div className="mb-10">
            <h2 className="text-2xl font-semibold mb-2">TABLE OF CONTENTS</h2>
            <ol className="list-decimal list-inside text-gray-700 mb-4 ps-3">
              <li
                onClick={(e) =>
                  scrollToSection(e, "what-information-do-we-collect")
                }
                className="cursor-pointer"
              >
                WHAT INFORMATION DO WE COLLECT?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "how-do-we-process-your-information")
                }
                className="cursor-pointer"
              >
                HOW DO WE PROCESS YOUR INFORMATION?
              </li>
              <li
                onClick={(e) => scrollToSection(e, "legal-bases")}
                className="cursor-pointer"
              >
                WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </li>
              <li
                onClick={(e) => scrollToSection(e, "when-and-with-whom-do-we-share-your-personal-information")}
                className="cursor-pointer"
              >
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </li>
              <li
                onClick={(e) => scrollToSection(e, "do-we-use-cookies-and-other-tracking-technologies")}
                className="cursor-pointer"
              >
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "how-do-we-handle-your-social-logins")
                }
                className="cursor-pointer"
              >
                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </li>
              <li
                onClick={(e) => scrollToSection(e, "how-long-do-we-keep")}
                className="cursor-pointer"
              >
                HOW LONG DO WE KEEP YOUR INFORMATION?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "how-do-we-keep-your-information-safe")
                }
                className="cursor-pointer"
              >
                HOW DO WE KEEP YOUR INFORMATION SAFE?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "what-are-your-privacy-rights")
                }
                className="cursor-pointer"
              >
                WHAT ARE YOUR PRIVACY RIGHTS?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "controls-for-do-not-track-features")
                }
                className="cursor-pointer"
              >
                CONTROLS FOR DO-NOT-TRACK FEATURES
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(
                    e,
                    "do-united-states-residents-have-specific-privacy-rights"
                  )
                }
                className="cursor-pointer"
              >
                DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(
                    e,
                    "do-other-regions-have-specific-privacy-rights"
                  )
                }
                className="cursor-pointer"
              >
                DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "do-we-make-updates-to-this-notice")
                }
                className="cursor-pointer"
              >
                DO WE MAKE UPDATES TO THIS NOTICE?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "how-can-you-contact-us-about-this-notice")
                }
                className="cursor-pointer"
              >
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </li>
              <li
                onClick={(e) =>
                  scrollToSection(e, "how-can-you-review-update-or-delete")
                }
                className="cursor-pointer"
              >
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </li>
            </ol>
          </div>
          {/* first section */}
          <div>
            <h2
              id="what-information-do-we-collect"
              className="text-2xl font-semibold mb-2 mt-4"
            >
              1.WHAT INFORMATION DO WE COLLECT?
            </h2>
            <div>
              <p className="text-black text-xl pt-3 font-semibold mb-4 font-semibold">
                Personal information you disclose to us{" "}
              </p>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> We collect personal
                information that you provide to us.
              </p>
              <p className="text-gray-700 mb-4">
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </p>
              <p className="text-gray-700 mb-4 ">
                <span className="font-bold">
                  Personal Information Provided by You.
                </span>{" "}
                The personal information that we collect depends on the context
                of your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
              </p>
              <ul className="list-disc list-inside ps-3">
                <li>names</li>
                <li>phone numbers</li>
                <li>email addresses</li>
                <li>usernames</li>
                <li>passwords</li>
                {/* Other list items */}
              </ul>
              <p className="text-gray-700 mb-4 ">
                <span className="font-bold">Sensitive Information. </span> When
                necessary, with your consent or as otherwise permitted by
                applicable law, we process the following categories of sensitive
                information:
              </p>
              <ul className="list-disc list-inside ps-3">
                <li>health data</li>
              </ul>
              <p className="text-gray-700 mb-4 ">
                <span className="font-bold">Social Media Login Data. </span> We
                may provide you with the option to register with us using your
                existing social media account details, like your Facebook,
                Twitter, or other social media account. If you choose to
                register in this way, we will collect the information described
                in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
                below.
              </p>
              <p className="text-gray-700 mb-4 ">
                <span className="font-bold">Application Data. </span> If you use
                our application(s), we also may collect the following
                information if you choose to provide us with access or
                permission:
              </p>
              <ul className="list-disc list-inside ps-3">
                <li className="text-gray-700 mb-4 ">
                  {" "}
                  <span className="font-bold">
                    Geolocation Information.{" "}
                  </span>{" "}
                  We may request access or permission to track location-based
                  information from your mobile device, either continuously or
                  while you are using our mobile application(s), to provide
                  certain location-based services. If you wish to change our
                  access or permissions, you may do so in your device's
                  settings.
                </li>
                <li className="text-gray-700 mb-4 ">
                  {" "}
                  <span className="font-bold">Mobile Device Data. </span>We
                  automatically collect device information (such as your mobile
                  device ID, model, and manufacturer), operating system, version
                  information and system configuration information, device and
                  application identification numbers, browser type and version,
                  hardware model Internet service provider and/or mobile
                  carrier, and Internet Protocol (IP) address (or proxy server).
                  If you are using our application(s), we may also collect
                  information about the phone network associated with your
                  mobile device, your mobile device’s operating system or
                  platform, the type of mobile device you use, your mobile
                  device’s unique device ID, and information about the features
                  of our application(s) you accessed.
                </li>
                <li className="text-gray-700 mb-4 ">
                  {" "}
                  <span className="font-bold">Push Notifications. </span> We may
                  request to send you push notifications regarding your account
                  or certain features of the application(s). If you wish to opt
                  out from receiving these types of communications, you may turn
                  them off in your device's settings.
                </li>
              </ul>
              <p className="text-gray-700 mb-4 ">
                This information is primarily needed to maintain the security
                and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.
              </p>
              <p className="text-gray-700 mb-4 ">
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </p>
            </div>

            <div>
              <p className="text-black text-xl pt-3 font-semibold mb-4 font-semibold">
                Information automatically collected
              </p>
              <div>
                <p className="text-gray-700 mb-4 italic">
                  <span className="font-bold">In Short:</span> Some information
                  — such as your Internet Protocol (IP) address and/or browser
                  and device characteristics — is collected automatically when
                  you visit our Services.
                </p>
                <p className="text-gray-700 mb-4">
                  We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services, and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes.
                </p>
                <p className="text-gray-700 mb-4">
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                </p>
                <p className="text-gray-700 mb-4">
                  The information we collect includes:
                </p>
                <ul className="list-disc list-inside ps-3">
                  <li>
                    <span className="font-semibold">Log and Usage Data.</span>{" "}
                    Log and usage data is service-related, diagnostic, usage,
                    and performance information our servers automatically
                    collect when you access or use our Services and which we
                    record in log files. Depending on how you interact with us,
                    this log data may include your IP address, device
                    information, browser type, and settings and information
                    about your activity in the Services (such as the date/time
                    stamps associated with your usage, pages and files viewed,
                    searches, and other actions you take such as which features
                    you use), device event information (such as system activity,
                    error reports (sometimes called "crash dumps"), and hardware
                    settings).
                  </li>
                  <li>
                    <span className="font-semibold">Location Data.</span> We
                    collect location data such as information about your
                    device's location, which can be either precise or imprecise.
                    How much information we collect depends on the type and
                    settings of the device you use to access the Services. For
                    example, we may use GPS and other technologies to collect
                    geolocation data that tells us your current location (based
                    on your IP address). You can opt out of allowing us to
                    collect this information either by refusing access to the
                    information or by disabling your Location setting on your
                    device. However, if you choose to opt out, you may not be
                    able to use certain aspects of the Services.
                  </li>
                </ul>
              </div>
            </div>

            {/* Additional subheadings and paragraphs */}
            {/* Repeat this structure for other sections */}
          </div>
          {/* Second section */}
          <div>
            <h2
              id="how-do-we-process-your-information"
              className="text-2xl font-semibold mb-2 mt-4"
            >
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> We process your
                information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to
                comply with law. We may also process your information for other
                purposes with your consent.
              </p>
              <p className="text-gray-700 mb-4">
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </p>
              <ul className="list-disc list-inside ps-3">
                <li>
                  <span className="font-bold">
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </span>{" "}
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </li>
                <li>
                  <span className="font-bold">
                    To save or protect an individual's vital interest.
                  </span>{" "}
                  We may process your information when necessary to save or
                  protect an individual’s vital interest, such as to prevent
                  harm.
                </li>
                {/* Add more list items similarly */}
              </ul>
            </div>
          </div>
          {/* Third section */}
          <div>
            <h2
              id="what-legal-bases-do-we-rely-on-to-process-your-information"
              className="text-2xl font-semibold mb-2 mt-4"
            >
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> We only process
                your personal information when we believe it is necessary and we
                have a valid legal reason (i.e., legal basis) to do so under
                applicable law, like with your consent, to comply with laws, to
                provide you with services to enter into or fulfill our
                contractual obligations, to protect your rights, or to fulfill
                our legitimate business interests.
              </p>
              <p className="text-gray-700 mb-4 font-bold underline">
                If you are located in the EU or UK, this section applies to you.
              </p>
              <p className="text-gray-700 mb-4">
                The General Data Protection Regulation (GDPR) and UK GDPR
                require us to explain the valid legal bases we rely on in order
                to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
              </p>
              <ul className="list-disc list-inside ps-3">
                <li>
                  <span className="font-bold">Consent.</span> We may process
                  your information if you have given us permission (i.e.,
                  consent) to use your personal information for a specific
                  purpose. You can withdraw your consent at any time. Learn more
                  about <a href="#withdraw-consent">withdrawing your consent</a>
                  .
                </li>
                <li>
                  <span className="font-bold">Legal Obligations.</span> We may
                  process your information where we believe it is necessary for
                  compliance with our legal obligations, such as to cooperate
                  with a law enforcement body or regulatory agency, exercise or
                  defend our legal rights, or disclose your information as
                  evidence in litigation in which we are involved.
                </li>
                <li>
                  <span className="font-bold">Vital Interests.</span> We may
                  process your information where we believe it is necessary to
                  protect your vital interests or the vital interests of a third
                  party, such as situations involving potential threats to the
                  safety of any person.
                </li>
              </ul>
            </div>
            <p className="text-gray-700 mb-4 font-bold underline">
              If you are located in Canada, this section applies to you.
            </p>
            <p className="text-gray-700 mb-4">
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time.
            </p>
            <p className="text-gray-700 mb-4">
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <ul className="list-disc list-inside ps-3">
              <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </li>
              <li>For investigations and fraud detection and prevention</li>
              <li>
                For business transactions provided certain conditions are met
              </li>
              <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </li>
              <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>
              <li>
                If we have reasonable grounds to believe an individual has been,
                is, or may be a victim of financial abuse
              </li>
              <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </li>
              <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>
              <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </li>
              <li>
                If the information is publicly available and is specified by the
                regulations
              </li>
            </ul>
          </div>
          {/* Fourth section */}
          <div>
            <h2
              id="when-and-with-whom-do-we-share-your-personal-information"
              className="text-2xl font-semibold mb-2 mt-4"
            >
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> We may share
                information in specific situations described in this section
                and/or with the following third parties.
              </p>
              <p className="text-gray-700 mb-4">
                We may need to share your personal information in the following
                situations:
              </p>
              <ul className="list-disc list-inside ps-3">
                <li>
                  <span className="font-bold">Business Transfers.</span> We may
                  share or transfer your information in connection with, or
                  during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </li>
                <li>
                  <span className="font-bold">
                    When we use Google Maps Platform APIs.
                  </span>{" "}
                  We may share your information with certain Google Maps
                  Platform APIs (e.g., Google Maps API, Places API). We obtain
                  and store on your device ("cache") your location. You may
                  revoke your consent anytime by contacting us at the contact
                  details provided at the end of this document.
                </li>
                <li>
                  <span className="font-bold">Offer Wall.</span> Our
                  application(s) may display a third-party hosted "offer wall."
                  Such an offer wall allows third-party advertisers to offer
                  virtual currency, gifts, or other items to users in return for
                  the acceptance and completion of an advertisement offer. Such
                  an offer wall may appear in our application(s) and be
                  displayed to you based on certain data, such as your
                  geographic area or demographic information. When you click on
                  an offer wall, you will be brought to an external website
                  belonging to other persons and will leave our application(s).
                  A unique identifier, such as your user ID, will be shared with
                  the offer wall provider in order to prevent fraud and properly
                  credit your account with the relevant reward.
                </li>
              </ul>
            </div>
          </div>
          {/* Fifth section */}
          <div>
            <h2
              id="do-we-use-cookies-and-other-tracking-technologies"
              className="text-2xl font-semibold mb-2 mt-5"
            >
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> We may use cookies
                and similar tracking technologies to collect and store your
                information.
              </p>
              <p className="text-gray-700 mb-4">
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </p>
            </div>
          </div>
          {/* Sixth section */}
          <div>
            <h2
              id="how-do-we-handle-your-social-logins"
              className="text-2xl font-semibold mb-2 mt-5"
            >
              6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> If you choose to
                register or log in to our Services using a social media account,
                we may have access to certain information about you.
              </p>
              <p className="text-gray-700 mb-4">
                Our Services offer you the ability to register and log in using
                your third-party social media account details (like your
                Facebook or Twitter logins). Where you choose to do this, we
                will receive certain profile information about you from your
                social media provider. The profile information we receive may
                vary depending on the social media provider concerned, but will
                often include your name, email address, friends list, and
                profile picture, as well as other information you choose to make
                public on such a social media platform.
              </p>
              <p className="text-gray-700 mb-4">
                We will use the information we receive only for the purposes
                that are described in this privacy notice or that are otherwise
                made clear to you on the relevant Services. Please note that we
                do not control, and are not responsible for, other uses of your
                personal information by your third-party social media provider.
                We recommend that you review their privacy notice to understand
                how they collect, use, and share your personal information, and
                how you can set your privacy preferences on their sites and
                apps.
              </p>
            </div>
          </div>
          {/* Seventh section */}
          <div>
            <h2
              id="how-long-do-we-keep"
              className="text-2xl font-semibold mb-2"
            >
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> We keep your
                information for as long as necessary to fulfill the purposes
                outlined in this privacy notice unless otherwise required by
                law.
              </p>
              <p className="text-gray-700 mb-4">
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us.
              </p>
              <p className="text-gray-700 mb-4">
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </div>
          </div>
          {/* Eighth section */}
          <div>
            <h2
              id="how-do-we-keep-your-information-safe"
              className="text-2xl font-semibold mb-2"
            >
              8. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> We aim to protect
                your personal information through a system of organizational and
                technical security measures.
              </p>
              <p className="text-gray-700 mb-4">
                We have implemented appropriate and reasonable technical and
                organizational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
              </p>
            </div>
          </div>
          {/* Ninth section */}
          <div>
            <h2
              id="what-are-your-privacy-rights"
              className="text-2xl font-semibold mb-2"
            >
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </h2>
            <div>
              <p className="text-gray-700 mb-4 italic">
                <span className="font-bold">In Short:</span> In some regions,
                such as the European Economic Area (EEA), United Kingdom (UK),
                Switzerland, and Canada, you have rights that allow you greater
                access to and control over your personal information. You may
                review, change, or terminate your account at any time.
              </p>
              <p className="text-gray-700 mb-4">
                In some regions (like the EEA, UK, Switzerland, and Canada), you
                have certain rights under applicable data protection laws. These
                may include the right (i) to request access and obtain a copy of
                your personal information, (ii) to request rectification or
                erasure; (iii) to restrict the processing of your personal
                information; (vi) if applicable, to data portability; and (vii)
                not to be subject to automated decision-making. In certain
                circumstances, you may also have the right to object to the
                processing of your personal information. You can make such a
                request by contacting us by using the contact details provided
                in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                below.
              </p>
              <p className="text-gray-700 mb-4">
                We will consider and act upon any request in accordance with
                applicable data protection laws. If you are located in the EEA
                or UK and you believe we are unlawfully processing your personal
                information, you also have the right to complain to your Member
                State data protection authority or UK data protection authority.
                If you are located in Switzerland, you may contact the Federal
                Data Protection and Information Commissioner.
              </p>
              <p className="text-gray-700 mb-4">
                <span className="font-bold underline">
                  Withdrawing your consent:
                </span>{" "}
                If we are relying on your consent to process your personal
                information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw
                your consent at any time. You can withdraw your consent at any
                time by contacting us by using the contact details provided in
                the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or
                updating your preferences. However, please note that this will
                not affect the lawfulness of the processing before its
                withdrawal nor, when applicable law allows, will it affect the
                processing of your personal information conducted in reliance on
                lawful processing grounds other than consent.
              </p>
              <p className="text-gray-700 mb-4">
                <span className="font-bold text-black text-xl">
                  Account Information
                </span>{" "}
                <br />
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </p>
              <ul className="list-disc list-inside ps-3">
                <li>
                  Contact us using the contact information provided at{" "}
                  <a
                    href="/account-delete-request"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    oengoo.com/account-delete-request
                  </a>
                </li>
              </ul>
              <p className="text-gray-700 mb-4">
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </p>
              <p className="text-gray-700 mb-4">
                <span className="font-bold">
                  Cookies and similar technologies:
                </span>{" "}
                Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of our Services.
              </p>
              <p className="text-gray-700 mb-4">
                If you have questions or comments about your privacy rights, you
                may email us at
                <a
                  href="mailto:publishing@oengoo.com"
                  className="text-blue-500 underline"
                >
                  publishing@oengoo.com
                </a>
                .
              </p>
            </div>
          </div>
          {/* Tenth section */}
          <div>
            <h2
              id="controls-for-do-not-track-features"
              className="text-2xl font-semibold mb-2"
            >
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h2>
            <p className="text-gray-700 mb-4">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage, no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
          </div>
          {/* Eleventh section */}
          <div>
            <h2
              id="do-united-states-residents-have-specific-privacy-rights"
              className="text-2xl font-semibold mb-2"
            >
              11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">In Short:</span> If you are a resident
              of California, Colorado or Virginia, you are granted specific
              rights regarding access to your personal information.
            </p>
            <p className="text-gray-700 mb-4">
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border-b p-2">Category</th>
                    <th className="border-b p-2">Examples</th>
                    <th className="border-b p-2">Collected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b p-2">A. Identifiers</td>
                    <td className="border-b p-2">
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, and account name
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">
                      B. Personal information as defined in the California
                      Customer Records statute
                    </td>
                    <td className="border-b p-2">
                      Name, contact information, education, employment,
                      employment history, and financial information
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">
                      C. Protected classification characteristics under state or
                      federal law
                    </td>
                    <td className="border-b p-2">Gender and date of birth</td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">D. Commercial information</td>
                    <td className="border-b p-2">
                      Transaction information, purchase history, financial
                      details, and payment information
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">E. Biometric information</td>
                    <td className="border-b p-2">
                      Fingerprints and voiceprints
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">
                      F. Internet or other similar network activity
                    </td>
                    <td className="border-b p-2">
                      Browsing history, search history, online behavior,
                      interest data, and interactions with our and other
                      websites, applications, systems, and advertisements
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">G. Geolocation data</td>
                    <td className="border-b p-2">Device location</td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">
                      H. Audio, electronic, visual, thermal, olfactory, or
                      similar information
                    </td>
                    <td className="border-b p-2">
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">
                      I. Professional or employment-related information
                    </td>
                    <td className="border-b p-2">
                      Business contact details in order to provide you our
                      Services at a business level or job title, work history,
                      and professional qualifications if you apply for a job
                      with us
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">J. Education Information</td>
                    <td className="border-b p-2">
                      Student records and directory information
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">
                      K. Inferences drawn from collected personal information
                    </td>
                    <td className="border-b p-2">
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual’s preferences and
                      characteristics
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                  <tr>
                    <td className="border-b p-2">
                      L. Sensitive personal Information
                    </td>
                    <td className="border-b p-2">
                      Account login information and health data
                    </td>
                    <td className="border-b p-2">YES</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="text-gray-700 mb-4">
              We will use and retain the collected personal information as
              needed to provide the Services or for:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Category A - As long as the user has an account with us</li>
              <li>Category B - As long as the user has an account with us</li>
              <li>Category C - As long as the user has an account with us</li>
              <li>Category D - As long as the user has an account with us</li>
              <li>Category E - As long as the user has an account with us</li>
              <li>Category F - As long as the user has an account with us</li>
              <li>Category G - As long as the user has an account with us</li>
              <li>Category H - As long as the user has an account with us</li>
              <li>Category I - As long as the user has an account with us</li>
              <li>Category J - As long as the user has an account with us</li>
              <li>Category K - As long as the user has an account with us</li>
              <li>Category L - As long as the user has an account with us</li>
              {/* ... Repeat for each category ... */}
            </ul>
            <p className="text-gray-700 mb-4">
              Category L information may be used, or disclosed to a service
              provider or contractor, for additional, specified purposes. You
              have the right to limit the use or disclosure of your sensitive
              personal information.
            </p>
            <p className="text-gray-700 mb-4">
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                How do we use and share your personal information?
              </span>
            </p>
            <p className="text-gray-700 mb-4">
              Learn about how we use your personal information in the section,{" "}
              <span onClick={(e) => scrollToSection(e, "how-do-we-process-your-information")} className="cursor-pointer text-blue-500 underline">
                "HOW DO WE PROCESS YOUR INFORMATION?"
              </span>
            </p>
            <p className="text-gray-700 mb-4">
              <span className="font-bold">
                Will your information be shared with anyone else?
              </span>
            </p>
            <p className="text-gray-700 mb-4">
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Learn more about how we disclose personal
              information in the section,{" "}
              <span
                onClick={(e) => scrollToSection(e, "when-and-with-whom-do-we-share-your-personal-information")}
                className="cursor-pointer text-blue-500 underline"
              >
                "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
              </span>
            </p>
            <p className="text-gray-700 mb-4">
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal information.
            </p>
            <p className="text-gray-700 mb-4">
              We have not disclosed, sold, or shared any personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months. We will not sell or share personal
              information in the future belonging to website visitors, users,
              and other consumers.
            </p>
            {/* sub part 1 */}
            <div>
              <h5 className="text-md font-semibold mb-2">
                California Residents
              </h5>
              <p className="text-gray-700 mb-4">
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law permits our users who are California residents to
                request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </p>
              <p className="text-gray-700 mb-4">
                If you are under 18 years of age, reside in California, and have
                a registered account with the Services, you have the right to
                request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us
                using the contact information provided below and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Services, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g., backups, etc.).
              </p>
              <h3 className="text-xl font-semibold mb-2">
                CCPA Privacy Notice
              </h3>
              <p className="text-gray-700 mb-4">
                This section applies only to California residents. Under the
                California Consumer Privacy Act (CCPA), you have the rights
                listed below.
              </p>
              <p className="text-gray-700 mb-4">
                The California Code of Regulations defines a "residents" as:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>
                  (1) every individual who is in the State of California for
                  other than a temporary or transitory purpose and
                </li>
                <li>
                  (2) every individual who is domiciled in the State of
                  California who is outside the State of California for a
                  temporary or transitory purpose
                </li>
              </ul>
              <p className="text-gray-700 mb-4">
                All other individuals are defined as "non-residents."
              </p>
              <p className="text-gray-700 mb-4">
                If this definition of "resident" applies to you, we must adhere
                to certain rights and obligations regarding your personal
                information.
              </p>
              <p className="text-md font-semibold mb-2">
                Your rights with respect to your personal data
              </p>

              <p className="text-gray-700 underline  mb-2">
                Right to request deletion of the data — Request to delete
              </p>
              <p className="text-gray-700 mb-4">
                You can ask for the deletion of your personal information. If
                you ask us to delete your personal information, we will respect
                your request and delete your personal information, subject to
                certain exceptions provided by law, such as (but not limited to)
                the exercise by another consumer of his or her right to free
                speech, our compliance requirements resulting from a legal
                obligation, or any processing that may be required to protect
                against illegal activities.
              </p>

              {/* ... Previous content ... */}
              <h4 className="text-md font-semibold mb-2">
                Right to be informed — Request to know
              </h4>
              <p className="text-gray-700 mb-4">
                Depending on the circumstances, you have a right to know:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>whether we collect and use your personal information;</li>
                <li>the categories of personal information that we collect;</li>
                <li>
                  the purposes for which the collected personal information is
                  used;
                </li>
                <li>
                  whether we sell or share personal information to third
                  parties;
                </li>
                <li>
                  the categories of personal information that we sold, shared,
                  or disclosed for a business purpose;
                </li>
                <li>
                  the categories of third parties to whom the personal
                  information was sold, shared, or disclosed for a business
                  purpose;
                </li>
                <li>
                  the business or commercial purpose for collecting, selling, or
                  sharing personal information; and
                </li>
                <li>
                  the specific pieces of personal information we collected about
                  you.
                </li>
              </ul>
              <p className="text-gray-700 mb-4">
                In accordance with applicable law, we are not obligated to
                provide or delete consumer information that is de-identified in
                response to a consumer request or to re-identify individual data
                to verify a consumer request.
              </p>
              <h4 className="text-md font-semibold mb-2">
                Right to Non-Discrimination for the Exercise of a Consumer’s
                Privacy Rights
              </h4>
              <p className="text-gray-700 mb-4">
                We will not discriminate against you if you exercise your
                privacy rights.
              </p>
              <h4 className="text-md font-semibold mb-2">
                Right to Limit Use and Disclosure of Sensitive Personal
                Information
              </h4>
              <p className="text-gray-700 mb-4">
                If the business collects any of the following:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>
                  social security information, drivers' licenses, state ID
                  cards, passport numbers
                </li>
                <li>account login information</li>
                <li>
                  credit card numbers, financial account information, or
                  credentials allowing access to such accounts
                </li>
                <li>precise geolocation</li>
                <li>
                  racial or ethnic origin, religious or philosophical beliefs,
                  union membership
                </li>
                <li>
                  the contents of email and text, unless the business is the
                  intended recipient of the communication
                </li>
                <li>genetic data, biometric data, and health data</li>
                <li>data concerning sexual orientation and sex life</li>
              </ul>
              <p className="text-gray-700 mb-4">
                you have the right to direct that business to limit its use of
                your sensitive personal information to that use which is
                necessary to perform the Services.
              </p>
              <p className="text-gray-700 mb-4">
                Once a business receives your request, they are no longer
                allowed to use or disclose your sensitive personal information
                for any other purpose unless you provide consent for the use or
                disclosure of sensitive personal information for additional
                purposes.
              </p>
              <p className="text-gray-700 mb-4">
                Please note that sensitive personal information that is
                collected or processed without the purpose of inferring
                characteristics about a consumer is not covered by this right,
                as well as the publicly available information.
              </p>
              <p className="text-gray-700 mb-4">
                To exercise your right to limit use and disclosure of sensitive
                personal information, please email publishing@oengoo.com or
                submit a data subject access request.
              </p>
              <h4 className="text-md font-semibold mb-2">
                Verification process
              </h4>
              <p className="text-gray-700 mb-4">
                Upon receiving your request, we will need to verify your
                identity to determine you are the same person about whom we have
                the information in our system. These verification efforts
                require us to ask you to provide information so that we can
                match it with information you have previously provided us. For
                instance, depending on the type of request you submit, we may
                ask you to provide certain information so that we can match the
                information you provide with the information we already have on
                file, or we may contact you through a communication method
                (e.g., phone or email) that you have previously provided to us.
                We may also use other verification methods as the circumstances
                dictate.
              </p>
              <p className="text-gray-700 mb-4">
                We will only use personal information provided in your request
                to verify your identity or authority to make the request. To the
                extent possible, we will avoid requesting additional information
                from you for the purposes of verification. However, if we cannot
                verify your identity from the information already maintained by
                us, we may request that you provide additional information for
                the purposes of verifying your identity and for security or
                fraud-prevention purposes. We will delete such additionally
                provided information as soon as we finish verifying you.
              </p>
              <h4 className="text-md font-semibold mb-2">
                Other privacy rights
              </h4>
              <ul className="list-disc list-inside mb-4">
                <li>
                  You may object to the processing of your personal information.
                </li>
                <li>
                  You may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the information.
                </li>
                <li>
                  You can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </li>
                <li>
                  You may request to opt out from future selling or sharing of
                  your personal information to third parties. Upon receiving an
                  opt-out request, we will act upon the request as soon as
                  feasibly possible, but no later than fifteen (15) days from
                  the date of the request submission.
                </li>
              </ul>
              <p className="text-gray-700 mb-4">
                To exercise these rights, you can contact us by submitting a
                data subject access request, by email at publishing@oengoo.com,
                or by referring to the contact details at the bottom of this
                document. If you have a complaint about how we handle your data,
                we would like to hear from you.
              </p>
            </div>
            {/* Content for Colorado Residents */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4">Colorado Residents</h3>
              <p className="text-gray-700 mb-4">
                This section applies only to Colorado residents. Under the
                Colorado Privacy Act (CPA), you have the rights listed below.
                However, these rights are not absolute, and in certain cases we
                may decline your request as permitted by law.
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>
                  Right to be informed whether or not we are processing your
                  personal data
                </li>
                <li>Right to access your personal data</li>
                <li>Right to correct inaccuracies in your personal data</li>
                <li>Right to request deletion of your personal data</li>
                <li>
                  Right to obtain a copy of the personal data you previously
                  shared with us
                </li>
                <li>
                  Right to opt out of the processing of your personal data if it
                  is used for targeted advertising, the sale of personal data,
                  or profiling in furtherance of decisions that produce legal or
                  similarly significant effects ("profiling")
                </li>
              </ul>
              <p className="text-gray-700 mb-4">
                To submit a request to exercise these rights described above,
                please email{" "}
                <a
                  href="mailto:publishing@oengoo.com"
                  className="text-blue-500"
                >
                  publishing@oengoo.com
                </a>{" "}
                or submit a data subject access request.
              </p>
              <p className="text-gray-700 mb-4">
                If we decline to take action regarding your request and you wish
                to appeal our decision, please email us at{" "}
                <a
                  href="mailto:publishing@oengoo.com"
                  className="text-blue-500"
                >
                  publishing@oengoo.com
                </a>
                . Within forty-five (45) days of receipt of an appeal, we will
                inform you in writing of any action taken or not taken in
                response to the appeal, including a written explanation of the
                reasons for the decisions.
              </p>
            </div>

            {/* Content for Connecticut Residents */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4">
                Connecticut Residents
              </h3>
              <p className="text-gray-700 mb-4">
                This section applies only to Connecticut residents. Under the
                Connecticut Data Privacy Act (CTDPA), you have the rights listed
                below. However, these rights are not absolute, and in certain
                cases we may decline your request as permitted by law.
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>
                  Right to be informed whether or not we are processing your
                  personal data
                </li>
                <li>Right to access your personal data</li>
                <li>Right to correct inaccuracies in your personal data</li>
                <li>Right to request deletion of your personal data</li>
                <li>
                  Right to obtain a copy of the personal data you previously
                  shared with us
                </li>
                <li>
                  Right to opt out of the processing of your personal data if it
                  is used for targeted advertising, the sale of personal data,
                  or profiling in furtherance of decisions that produce legal or
                  similarly significant effects ("profiling")
                </li>
              </ul>
              <p className="text-gray-700 mb-4">
                To submit a request to exercise these rights described above,
                please email{" "}
                <a
                  href="mailto:publishing@oengoo.com"
                  className="text-blue-500"
                >
                  publishing@oengoo.com
                </a>{" "}
                or submit a data subject access request.
              </p>
              <p className="text-gray-700 mb-4">
                If we decline to take action regarding your request and you wish
                to appeal our decision, please email us at{" "}
                <a
                  href="mailto:publishing@oengoo.com"
                  className="text-blue-500"
                >
                  publishing@oengoo.com
                </a>
                . Within sixty (60) days of receipt of an appeal, we will inform
                you in writing of any action taken or not taken in response to
                the appeal, including a written explanation of the reasons for
                the decisions.
              </p>
            </div>

            {/* Content for Virginia Residents */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4">Virginia Residents</h3>
              <p className="text-gray-700 mb-4">
                Under the Virginia Consumer Data Protection Act (VCDPA):
              </p>
              <p className="text-gray-700 mb-4">
                "Consumer" means a natural person who is a resident of the
                Commonwealth acting only in an individual or household context.
                It does not include a natural person acting in a commercial or
                employment context.
              </p>
              <p className="text-gray-700 mb-4">
                "Personal data" means any information that is linked or
                reasonably linkable to an identified or identifiable natural
                person. "Personal data" does not include de-identified data or
                publicly available information.
              </p>
              <p className="text-gray-700 mb-4">
                "Sale of personal data" means the exchange of personal data for
                monetary consideration.
              </p>
              <p className="text-gray-700 mb-4">
                If this definition of "consumer" applies to you, we must adhere
                to certain rights and obligations regarding your personal data.
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>
                  Right to be informed whether or not we are processing your
                  personal data
                </li>
                <li>Right to access your personal data</li>
                <li>Right to correct inaccuracies in your personal data</li>
                <li>Right to request deletion of your personal data</li>
                <li>
                  Right to obtain a copy of the personal data you previously
                  shared with us
                </li>
                <li>
                  Right to opt out of the processing of your personal data if it
                  is used for targeted advertising, the sale of personal data,
                  or profiling in furtherance of decisions that produce legal or
                  similarly significant effects ("profiling")
                </li>
              </ul>
              <p className="text-gray-700 underline mb-4">
                <strong>
                  Exercise your rights provided under the Virginia VCDPA
                </strong>
              </p>
              <p className="text-gray-700 mb-4">
                You may contact us by email at{" "}
                <a
                  href="mailto:publishing@oengoo.com"
                  className="text-blue-500"
                >
                  publishing@oengoo.com
                </a>{" "}
                or submit a data subject access request.
              </p>
              <p className="text-gray-700 mb-4">
                If you are using an authorized agent to exercise your rights, we
                may deny a request if the authorized agent does not submit proof
                that they have been validly authorized to act on your behalf.
              </p>
              <p className="text-gray-700 underline mb-4">
                <strong>Verification process</strong>
              </p>
              <p className="text-gray-700 mb-4">
                We may request that you provide additional information
                reasonably necessary to verify you and your consumer's request.
                If you submit the request through an authorized agent, we may
                need to collect additional information to verify your identity
                before processing your request.
              </p>
              <p className="text-gray-700 mb-4">
                Upon receiving your request, we will respond without undue
                delay, but in all cases, within forty-five (45) days of receipt.
                The response period may be extended once by forty-five (45)
                additional days when reasonably necessary. We will inform you of
                any such extension within the initial 45-day response period,
                together with the reason for the extension.
              </p>
              <p className="text-gray-700 underline mb-4">
                <strong>Right to appeal</strong>
              </p>
              <p className="text-gray-700 mb-4">
                If we decline to take action regarding your request, we will
                inform you of our decision and reasoning behind it. If you wish
                to appeal our decision, please email us at{" "}
                <a
                  href="mailto:publishing@oengoo.com"
                  className="text-blue-500"
                >
                  publishing@oengoo.com
                </a>
                . Within sixty (60) days of receipt of an appeal, we will inform
                you in writing of any action taken or not taken in response to
                the appeal, including a written explanation of the reasons for
                the decisions. If your appeal is denied, you may contact the
                Attorney General to submit a complaint.
              </p>
            </div>
          </div>
          {/* Tweleth Section */}
          <div className="mt-8">
            <h3
              id="do-other-regions-have-specific-privacy-rights"
              className="text-2xl font-semibold mb-4"
            >
              12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
            </h3>
            <p className="text-gray-700 mb-4">
              <strong>In Short:</strong> You may have additional rights based on
              the country you reside in.
            </p>

            <p className="text-gray-700 mb-4">
              <strong>Australia and New Zealand</strong>
            </p>
            <p className="text-gray-700 mb-4">
              We collect and process your personal information under the
              obligations and conditions set by Australia's Privacy Act 1988 and
              New Zealand's Privacy Act 2020 (Privacy Act).
            </p>
            <p className="text-gray-700 mb-4">
              This privacy notice satisfies the notice requirements defined in
              both Privacy Acts, in particular: what personal information we
              collect from you, from which sources, for which purposes, and
              other recipients of your personal information.
            </p>
            <p className="text-gray-700 mb-4">
              If you do not wish to provide the personal information necessary
              to fulfill their applicable purpose, it may affect our ability to
              provide our services, in particular:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>offer you the products or services that you want</li>
              <li>respond to or help with your requests</li>
              <li>manage your account with us</li>
              <li>confirm your identity and protect your account</li>
            </ul>
            <p className="text-gray-700 mb-4">
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?"
            </p>
            <p className="text-gray-700 mb-4">
              If you believe we are unlawfully processing your personal
              information, you have the right to submit a complaint about a
              breach of the Australian Privacy Principles to the Office of the
              Australian Information Commissioner and a breach of New Zealand's
              Privacy Principles to the Office of New Zealand Privacy
              Commissioner.
            </p>

            <p className="text-gray-700 mb-4">
              <strong>Republic of South Africa</strong>
            </p>
            <p className="text-gray-700 mb-4">
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?"
            </p>
            <p className="text-gray-700 mb-4">
              If you are unsatisfied with the manner in which we address any
              complaint with regard to our processing of personal information,
              you can contact the office of the regulator, the details of which
              are:
            </p>
            <p className="text-gray-700 mb-4">
              The Information Regulator (South Africa)
            </p>
            <p className="text-gray-700 mb-4">
              General enquiries:{" "}
              <a
                href="mailto:enquiries@inforegulator.org.za"
                className="text-blue-500"
              >
                enquiries@inforegulator.org.za
              </a>
            </p>
            <p className="text-gray-700 mb-4">
              Complaints (complete POPIA/PAIA form 5):{" "}
              <a
                href="mailto:PAIAComplaints@inforegulator.org.za"
                className="text-blue-500"
              >
                PAIAComplaints@inforegulator.org.za
              </a>{" "}
              &{" "}
              <a
                href="mailto:POPIAComplaints@inforegulator.org.za"
                className="text-blue-500"
              >
                POPIAComplaints@inforegulator.org.za
              </a>
            </p>
          </div>
          {/* Thirteen Section */}
          <div className="mt-8">
            <h3
              id="do-we-make-updates-to-this-notice"
              className="text-2xl font-semibold mb-4"
            >
              13. DO WE MAKE UPDATES TO THIS NOTICE?
            </h3>
            <p className="text-gray-700 mb-4">
              <strong>In Short:</strong> Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </p>
            <p className="text-gray-700 mb-4">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </div>
          {/* Fourteenth section */}
          <div className="mt-8">
            <h3
              id="how-can-you-contact-us-about-this-notice"
              className="text-2xl font-semibold mb-4"
            >
              14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h3>
            <p className="text-gray-700 mb-4">
              If you have questions or comments about this notice, you may email
              us at{" "}
              <a
                href="mailto:publishing@oengoo.com"
                className="text-blue-600 hover:underline"
              >
                publishing@oengoo.com
              </a>{" "}
              or contact us by post at:
            </p>
            <p className="text-gray-700">
              Oengoo Private Limited
              <br />
              __________
              <br />
              __________
            </p>
          </div>

          {/* Fifteenth section */}
          <div className="mt-8">
            <h3
              id="how-can-you-review-update-or-delete"
              className="text-2xl font-semibold mb-4"
            >
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h3>
            <p className="text-gray-700 mb-4">
              You have the right to request access to the personal information
              we collect from you, change that information, or delete it. To
              request to review, update, or delete your personal information,
              please fill out and submit a{" "}
              <a
                href="link_to_data_subject_access_request_form"
                className="text-blue-600 hover:underline"
              >
                data subject access request
              </a>
              .
            </p>
            <p className="text-gray-700">
              This privacy policy was created using Termly's Privacy Policy
              Generator.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
